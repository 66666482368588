<script>
import TagsInput from '../components/shipments/TagsInput.vue';
import { verifyShipments, updateToPaid } from '../services/shipments.service';

export default {
  name: 'VerifyShipments',
  components: { TagsInput },
  data() {
    return {
      requestLoading: false,
      apiResponse: { delivered: [], undelivered: [], notExist: [] },
      loading: [false, false, false], // Use an array for loading
      displayedItems: [[], [], []], // Use an array for displayed items
      itemsPerPage: 20,
      itemsPerPage: 20,
      trackingNumbers: [],
    };
  },
  mounted() { },
  methods: {
    handleScroll(index) {
      const container = this.$refs.scrollContainer[index];
      const bottomOfContainer =
        container.scrollHeight - container.clientHeight - container.scrollTop;

      if (bottomOfContainer < 50 && !this.loading[index]) {
        this.loadMore(index);
      }
    },
    loadMore(index) {
      this.loading[index] = true;

      // Simulate an async operation, replace this with your actual data fetch
      setTimeout(() => {
        const startIndex = this.displayedItems[index].length;
        const newItems = this.apiResponse[
          Object.keys(this.apiResponse)[index]
        ].slice(startIndex, startIndex + this.itemsPerPage);

        // Your logic to push more items to the displayedItems array
        this.displayedItems[index].push(...newItems);

        // Set loading back to false when done
        this.loading[index] = false;
      }, 1000); // Adjust the delay as needed
    },

    updateTags(updatedTags) {
      this.trackingNumbers = updatedTags;
    },
    async handleVerify() {
      this.requestLoading = true;
      this.displayedItems = [[], [], []];

      if (this.trackingNumbers.length) {
        this.apiResponse = (
          await verifyShipments(this.trackingNumbers)
        ).content;
        this.loadMore(0);
        this.loadMore(1);
        this.loadMore(2);
      }
      this.requestLoading = false;
    },
    async handleUpdateToPaid() {
      this.requestLoading = true;
      const result = await updateToPaid(this.apiResponse.undelivered);
      if (result && result.content) {
        this.$toast('Updated Successfully');
        await this.handleVerify();
      } else {
        this.$toast.error('Something went wrong while updating');
      }
      this.requestLoading = false;
    },
  },
};
</script>

<template>
  <div class="bg-white p-12 m-6 rounded-md border border-gray-400">
    <h1 class="text-2xl font-semibold tracking-wider">Verify Shipments</h1>
    <span class="font-bold">{{ trackingNumbers.length }} Shipments</span>
    <TagsInput :tags="trackingNumbers" @update:tags="updateTags" />
    <div class="mt-6">
      <button @click="handleVerify"
        class="bg-blue-500 px-4 py-2 text-white outline-none border border-blue-500 focus:border-blue-900 rounded-md hover:bg-blue-600"
        :class="{
          'pointer-events-none': requestLoading,
        }">
        {{ requestLoading ? 'Processing' : 'Verify Now' }}
      </button>
    </div>

    <div class="relative overflow-x-auto mt-4">
      <div>
        <div v-for="(items, key, index) in apiResponse" :key="key" class=" my-4">
          <div class="flex items-center cursor-pointer bg-gray-200 px-4 py-6 justify-between">
            <div class="flex items-center gap-4">
              <h2 class=" rounded-t-md ">
                {{ key }}
              </h2>
              <span :class="{
                'text-green-100 bg-green-500': key === 'delivered',
                'text-yellow-100 bg-yellow-500': key === 'undelivered',
                'text-red-100 bg-red-500': key === 'notExist',
              }" class="text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300">{{
                items.length }}</span>
            </div>
            <button class="bg-blue-500 text-white px-3 py-2 rounded-md" v-if="key === 'undelivered' && items.length"
              @click="handleUpdateToPaid" :class="{
                'pointer-events-none': requestLoading,
              }">
              {{ requestLoading ? 'Processing' : 'Change to paid' }}
            </button>
          </div>
          <div ref="scrollContainer" @scroll="handleScroll(index)" class="border-gray-400 border"
            style="height: 250px; overflow-y: auto;">
            <table class="w-full whitespace-no-wrap">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Tracking Number
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Current Status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Is Paid ?
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in displayedItems[index]">
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-if="key !== 'notExist'">
                    <th scope="row" style="    
                        display: inline-block;
                        line-height: 50px;
                        overflow: hidden;
                        white-space: nowrap;
                        font-family: sans-serif;
                        padding: 0 .5em;
                        text-align: center;
                        width: 100%;" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {{ item.trackingNumber }}
                    </th>
                    <td class="px-6 py-4 text-center">
                      {{ item.status }}
                    </td>
                    <td class="px-6 py-4 text-center">
                      <span class="text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300"
                        :class="{
                          'text-blue-100 bg-blue-500':
                            item.is_paid.toUpperCase() === 'ACCEPT' ||
                            item.is_paid.toUpperCase() === 'VALID',
                          'text-yellow-100 bg-yellow-500':
                            item.is_paid.toUpperCase() !== 'ACCEPT',
                        }">
                        {{
                          item.is_paid.toUpperCase() === 'ACCEPT' ||
                            item.is_paid.toUpperCase() === 'VALID'
                            ? 'Paid'
                            : 'Not Paid'
                        }}
                      </span>
                    </td>
                    <td class="px-6 py-4 text-center">
                      {{ item.orderObjet.totalPrice }}
                    </td>
                  </tr>
                  <tr v-else class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" style="    
                        display: inline-block;
                        line-height: 50px;
                        overflow: hidden;
                        white-space: nowrap;
                        font-family: sans-serif;
                        padding: 0 .5em;
                        text-align: center;
                        width: 100%;" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {{ item }}
                    </th>
                    <td class="px-6 py-4 text-center">
                      <span
                        class="text-reed-100 bg-red-500 text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300">
                        Not Found
                      </span>
                    </td>
                    <td class="px-6 py-4 text-center">
                      <span
                        class="text-reed-100 bg-red-500 text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300">
                        Not Found
                      </span>
                    </td>
                    <td class="px-6 py-4 text-center">
                      <span
                        class="text-reed-100 bg-red-500 text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300">
                        Not Found
                      </span>
                    </td>
                  </tr>
                </template>
                <tr v-if="loading[index]">
                  <td colspan="4" class="font-bold text-center">Loading...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
