var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white p-12 m-6 rounded-md border border-gray-400"
  }, [_c('h1', {
    staticClass: "text-2xl font-semibold tracking-wider"
  }, [_vm._v("Verify Shipments")]), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.trackingNumbers.length) + " Shipments")]), _c('TagsInput', {
    attrs: {
      "tags": _vm.trackingNumbers
    },
    on: {
      "update:tags": _vm.updateTags
    }
  }), _c('div', {
    staticClass: "mt-6"
  }, [_c('button', {
    staticClass: "bg-blue-500 px-4 py-2 text-white outline-none border border-blue-500 focus:border-blue-900 rounded-md hover:bg-blue-600",
    class: {
      'pointer-events-none': _vm.requestLoading
    },
    on: {
      "click": _vm.handleVerify
    }
  }, [_vm._v(" " + _vm._s(_vm.requestLoading ? 'Processing' : 'Verify Now') + " ")])]), _c('div', {
    staticClass: "relative overflow-x-auto mt-4"
  }, [_c('div', _vm._l(_vm.apiResponse, function (items, key, index) {
    return _c('div', {
      key: key,
      staticClass: "my-4"
    }, [_c('div', {
      staticClass: "flex items-center cursor-pointer bg-gray-200 px-4 py-6 justify-between"
    }, [_c('div', {
      staticClass: "flex items-center gap-4"
    }, [_c('h2', {
      staticClass: "rounded-t-md"
    }, [_vm._v(" " + _vm._s(key) + " ")]), _c('span', {
      staticClass: "text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300",
      class: {
        'text-green-100 bg-green-500': key === 'delivered',
        'text-yellow-100 bg-yellow-500': key === 'undelivered',
        'text-red-100 bg-red-500': key === 'notExist'
      }
    }, [_vm._v(_vm._s(items.length))])]), key === 'undelivered' && items.length ? _c('button', {
      staticClass: "bg-blue-500 text-white px-3 py-2 rounded-md",
      class: {
        'pointer-events-none': _vm.requestLoading
      },
      on: {
        "click": _vm.handleUpdateToPaid
      }
    }, [_vm._v(" " + _vm._s(_vm.requestLoading ? 'Processing' : 'Change to paid') + " ")]) : _vm._e()]), _c('div', {
      ref: "scrollContainer",
      refInFor: true,
      staticClass: "border-gray-400 border",
      staticStyle: {
        "height": "250px",
        "overflow-y": "auto"
      },
      on: {
        "scroll": function scroll($event) {
          return _vm.handleScroll(index);
        }
      }
    }, [_c('table', {
      staticClass: "w-full whitespace-no-wrap"
    }, [_vm._m(0, true), _c('tbody', [_vm._l(_vm.displayedItems[index], function (item) {
      return [key !== 'notExist' ? _c('tr', {
        staticClass: "bg-white border-b dark:bg-gray-800 dark:border-gray-700"
      }, [_c('th', {
        staticClass: "px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white",
        staticStyle: {
          "display": "inline-block",
          "line-height": "50px",
          "overflow": "hidden",
          "white-space": "nowrap",
          "font-family": "sans-serif",
          "padding": "0 .5em",
          "text-align": "center",
          "width": "100%"
        },
        attrs: {
          "scope": "row"
        }
      }, [_vm._v(" " + _vm._s(item.trackingNumber) + " ")]), _c('td', {
        staticClass: "px-6 py-4 text-center"
      }, [_vm._v(" " + _vm._s(item.status) + " ")]), _c('td', {
        staticClass: "px-6 py-4 text-center"
      }, [_c('span', {
        staticClass: "text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300",
        class: {
          'text-blue-100 bg-blue-500': item.is_paid.toUpperCase() === 'ACCEPT' || item.is_paid.toUpperCase() === 'VALID',
          'text-yellow-100 bg-yellow-500': item.is_paid.toUpperCase() !== 'ACCEPT'
        }
      }, [_vm._v(" " + _vm._s(item.is_paid.toUpperCase() === 'ACCEPT' || item.is_paid.toUpperCase() === 'VALID' ? 'Paid' : 'Not Paid') + " ")])]), _c('td', {
        staticClass: "px-6 py-4 text-center"
      }, [_vm._v(" " + _vm._s(item.orderObjet.totalPrice) + " ")])]) : _c('tr', {
        staticClass: "bg-white border-b dark:bg-gray-800 dark:border-gray-700"
      }, [_c('th', {
        staticClass: "px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white",
        staticStyle: {
          "display": "inline-block",
          "line-height": "50px",
          "overflow": "hidden",
          "white-space": "nowrap",
          "font-family": "sans-serif",
          "padding": "0 .5em",
          "text-align": "center",
          "width": "100%"
        },
        attrs: {
          "scope": "row"
        }
      }, [_vm._v(" " + _vm._s(item) + " ")]), _vm._m(1, true), _vm._m(2, true), _vm._m(3, true)])];
    }), _vm.loading[index] ? _c('tr', [_c('td', {
      staticClass: "font-bold text-center",
      attrs: {
        "colspan": "4"
      }
    }, [_vm._v("Loading...")])]) : _vm._e()], 2)])])]);
  }), 0)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
  }, [_c('tr', [_c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Tracking Number ")]), _c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Current Status ")]), _c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Is Paid ? ")]), _c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Total Price ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "px-6 py-4 text-center"
  }, [_c('span', {
    staticClass: "text-reed-100 bg-red-500 text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300"
  }, [_vm._v(" Not Found ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "px-6 py-4 text-center"
  }, [_c('span', {
    staticClass: "text-reed-100 bg-red-500 text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300"
  }, [_vm._v(" Not Found ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "px-6 py-4 text-center"
  }, [_c('span', {
    staticClass: "text-reed-100 bg-red-500 text-xs font-medium mr-2 px-3 py-1 rounded dark:bg-blue-900 dark:text-blue-300"
  }, [_vm._v(" Not Found ")])]);

}]

export { render, staticRenderFns }