<template>
  <div class="my-6">
    <div class="flex items-center">
      <textarea
        class="border-blue-500 border outline-none"
        v-model="tagInput"
        @paste.prevent="processInput"
        @keydown.enter.prevent="addTag"
        placeholder="Add tracking numbers Or ids orders..."
      ></textarea>
      <button
        v-show="localTags.length"
        @click="handleReset"
        class="bg-blue-100 px-4 py-2 text-blue-500 ml-2 outline-none border border-blue-500 focus:border-blue-900 rounded-md hover:bg-blue-300 hover:text-blue-700"
      >
        Clear
      </button>
    </div>
    <div v-for="(tag, index) in displayedTags" :key="index" class="tag">
      <div class="flex gap-1 px-2 items-center">
        {{ tag.label }}
        <span
          @click="removeTag(index)"
          v-if="index < 4 || tags.length <= 5"
          class=" flex items-center justify-center"
        >
          <i class="material-icons">
            close
          </i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tagInput: '',
      lines: [],
      maxDisplayedTags: 10,
      localTags: [...this.tags], // Create a local copy to avoid mutating props
    };
  },
  computed: {
    displayedTags() {
      const tagsToDisplay = this.localTags.slice(0, this.maxDisplayedTags);
      const remainingTagsCount = this.localTags.length - this.maxDisplayedTags;

      if (remainingTagsCount > 0) {
        tagsToDisplay.push({
          label: `+${remainingTagsCount} more`,
          indicator: true,
        });
      }

      return tagsToDisplay;
    },
  },
  emits: ['update:tags'],
  methods: {
    handleReset() {
      this.$emit('update:tags', []);
      this.localTags = [];
    },
    processInput(evt) {
      const clipboardData = evt.clipboardData.getData('text/plain');
      const lines = clipboardData.split('\r\n');

      const newTags = lines
        .filter(line => line.trim() !== '')
        .map(tag => ({ label: tag.trim(), indicator: false }));

      this.localTags = [...this.localTags, ...newTags];
      this.$emit('update:tags', [...this.tags, ...newTags]); // Emit an event to update the parent prop
      this.tagInput = '';
    },

    addTag() {
      this.localTags.push({ label: this.tagInput, indicator: false });
      this.$emit('update:tags', [...this.localTags]); // Emit an event to update the parent prop

      this.tagInput = ''; // Clear the input
    },
    removeTag(index) {
      this.localTags.splice(index, 1);
      this.$emit('update:tags', [...this.localTags]); // Emit an event to update the parent prop
    },
  },
};
</script>

<style scoped>
.tag {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  background-color: #153bd4;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.tag span {
  margin-left: 5px;
  cursor: pointer;
}

textarea {
  width: 100%;
  padding: 10px;
}
</style>
