var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-6"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.tagInput,
      expression: "tagInput"
    }],
    staticClass: "border-blue-500 border outline-none",
    attrs: {
      "placeholder": "Add tracking numbers Or ids orders..."
    },
    domProps: {
      "value": _vm.tagInput
    },
    on: {
      "paste": function paste($event) {
        $event.preventDefault();
        return _vm.processInput.apply(null, arguments);
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.addTag.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.tagInput = $event.target.value;
      }
    }
  }), _c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.localTags.length,
      expression: "localTags.length"
    }],
    staticClass: "bg-blue-100 px-4 py-2 text-blue-500 ml-2 outline-none border border-blue-500 focus:border-blue-900 rounded-md hover:bg-blue-300 hover:text-blue-700",
    on: {
      "click": _vm.handleReset
    }
  }, [_vm._v(" Clear ")])]), _vm._l(_vm.displayedTags, function (tag, index) {
    return _c('div', {
      key: index,
      staticClass: "tag"
    }, [_c('div', {
      staticClass: "flex gap-1 px-2 items-center"
    }, [_vm._v(" " + _vm._s(tag.label) + " "), index < 4 || _vm.tags.length <= 5 ? _c('span', {
      staticClass: "flex items-center justify-center",
      on: {
        "click": function click($event) {
          return _vm.removeTag(index);
        }
      }
    }, [_c('i', {
      staticClass: "material-icons"
    }, [_vm._v(" close ")])]) : _vm._e()])]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }