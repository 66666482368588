import axios from '../common/axios';
export const dispatchShipment = async (payload, single = false) => {
  try {
    const response = await axios.post(
      `/shippings/${single ? 'dispatchOne' : 'dispatchMany'}`,
      payload,
    );
    console.log('response.data------------------------',response.data)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const verifyShipments = async trackingNumbers => {
  try {
    const response = await axios.post(
      '/shippings/verifyShipments',
      trackingNumbers,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateToPaid = async trackingNumbers => {
  try {
    const response = await axios.post(
      '/shippings/updateToPaid',
      trackingNumbers,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

